import { Component, OnInit } from '@angular/core';
import {CommonGeneralService} from '../services/common-general.service';


@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  terms;

  constructor(private service:CommonGeneralService) { }

  ngOnInit(): void {
    this.service.getTerms().subscribe((res:any)=>{
      this.terms = res.content[0];
      console.log (this.terms)
    })
  }

}

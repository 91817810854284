import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';
import { HttpHeaders,HttpRequest,HttpParams  } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  header:HttpHeaders = new HttpHeaders();


  constructor(private baseHttpService: BaseHttpService) { }

  submitloginForm(body) {
    return this.baseHttpService.post('/login_with_email', {
      ...body
    }, {})
  }

  submitloginAdminForm(body) {
    let headerNotification = this.header.set("backend",'1')

    return this.baseHttpService.post('/login_with_user', {
      ...body
    }, {headers:headerNotification})
  }

  submitRegisterForm(body) {
    return this.baseHttpService.post('/register', {
      ...body
    }, {})
  }

  submitEmailForm(body) {
    return this.baseHttpService.post('/change_email', {
      ...body
    }, {})
  }
 
  SendEmailVerification() {
    let mail = localStorage.getItem('email')
    return this.baseHttpService.post('/send_verification_code_to_email',
     {
      "email":mail
     }, {})
  }

  


  submitPasswordForm(body) {
    return this.baseHttpService.post('/forget_password', {
      ...body
    }, {})
  }

  submitPasswordForm1(body) {
    return this.baseHttpService.post('/verify_email_verification_code', {
      ...body
    }, {})
  }

  submitPasswordForm2(body) {
    
    return this.baseHttpService.post('/change_password_by_email', {
      ...body
    }, {})
  }




}

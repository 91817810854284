import { Component, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {


  registrationRef: Boolean = false;

  opened = true;
  register_popup = false;
  myRig = false;



  @Output() closeLoginPopup = new EventEmitter();
  opened2: boolean;
  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.noScroll);
  }

  close(event) {
    this.opened = false;
    event.preventDefault();
    window.removeEventListener('scroll', this.noScroll);
    this.valueChanged();
  }
  valueChanged() {
    this.opened = false;
    this.closeLoginPopup.emit(this.opened);
  }

  noScroll() {
    window.scrollTo(0, 0);
  }

  openRegistrationForm(event) {
    this.registrationRef = event;
  }

  closePop() {
    this.opened = false;
 }

 ClickedOut(event) {
    if(event.target.className === "hover_bkgr_fricc") {
      this.opened = false;
      console.log(event)
    } 
 }

//  onClick(event) {

//   if (!this.elementRef.nativeElement.contains(event.target)) // or some similar check
//     // alert('clicked')
//     //doSomething();
//   //  this.opened2 = false;

//  }

}

<a [routerLink]="[article.id]">
    <div class="card text-left" style="height: 350px;">
        <img class="card-img-top" [src]="article.image">
        <div class="card-body">
            <div style ="display: flex;">

                <!-- <time datetime="" class="icon">
                        
                    <em>{{article.created_at | dateFormatter: 'ddd':'ar'}}</em>
                    <strong>{{article.created_at | dateFormatter: 'MMM':'ar'}}</strong>
                    <span>{{article.created_at | dateFormatter: 'D':'ar'}}</span>
                </time> -->
                
                <h3 class="card-title">{{article.title}}</h3>

            </div>

                <p class="p-date">{{article.created_at}}</p>
                
                <p [innerHTML]="article.content" class=""></p>
                <!-- <img src="assets/images/Keion-Jackson.jpg" class="rounded-circle" alt="Cinque Terre">
                <p class="p-title1"> {{article.author}} </p> -->

        </div>
    </div>
</a>

import { Component, OnInit } from '@angular/core';
import { Website } from '../../models/website';
import {CommonGeneralService} from '../services/common-general.service';

@Component({
  selector: 'app-friendly-website',
  templateUrl: './friendly-website.component.html',
  styleUrls: ['./friendly-website.component.scss']
})
export class FriendlyWebsiteComponent implements OnInit {
  websites:Array<Website> = [
    {"name":'websiteName',
      "logo":"./assets/images/logo_default.png",
      "url":"http://www.google.com"
    }
  ]

  websiteslist;
  constructor(private service:CommonGeneralService) { }

  ngOnInit(): void {
    this.service.getFriendlyWebsiteslist().subscribe((res:any)=>{
      this.websiteslist = res.content
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { ArticlesService } from 'src/app/articles/services/articles.service';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from 'src/app/events/services/events.service';
import { InitiativesService } from 'src/app/initiatives/services/initiatives.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  
  eventList: any[];
  articles: any[];
  initiatives: any[];
  constructor(private activeRoute: ActivatedRoute,
              private newsService: ArticlesService, 
              private eventsService: EventsService,
              private initiativesService: InitiativesService) { }

  ngOnInit(): void {
    /* this.searchData(this.activeRoute.snapshot.queryParams['query']); */
    this.activeRoute.queryParams.subscribe(res => {
      this.searchData(this.activeRoute.snapshot.queryParams['query'])
    })
  }

  searchData(query) {
    this.newsService.getSearchNews(1, query).subscribe((res:any) => {
      this.articles = res.content;
      // console.log("Search Results=========>",this.initiatives)
    });

    this.eventsService.getSearchEvents(1, query).subscribe((res:any) => {
      this.eventList = res.content;
      // console.log("Search Results=========>",this.eventList)
    });
    this.initiativesService.getSearchInitiatives(1, query).subscribe((res:any) => {
      this.initiatives = res.content
    })
  }

}

<div class="login-page-style">
  
  <div class="popup hover_bkgr_fricc" *ngIf="opened" (click)="ClickedOut($event)">
    <span class="close" (click)="close($event);valueChanged()">
        <i class="fa fa-times"></i>
    </span>
    <div class="content">
      <app-login-popup *ngIf="!registrationRef" (registrationRef)="openRegistrationForm($event)"></app-login-popup>
      <!-- <app-register-popup *ngIf="registrationRef"></app-register-popup> -->
    </div>
  </div>
  <div class="style" *ngIf="opened"></div>  
  

</div>



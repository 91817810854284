import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {AuthenticationService} from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password2',
  templateUrl: './reset-password2.component.html',
  styleUrls: ['./reset-password2.component.scss']
})
export class ResetPassword2Component implements OnInit {

  registrationForm: FormGroup;
  Email_verify;
  Email_verification_code;
  param;

  constructor(formBuilder:FormBuilder,
             private authService: AuthenticationService,
             private toastr: ToastrService,
             private router: Router,
             private route: ActivatedRoute) {

    this.registrationForm  = formBuilder.group({
      

      email:['',Validators.compose([Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")])],

      password:['',Validators.compose([Validators.required])],
      confirm_password:['',Validators.compose([Validators.required])],


    })
    
   }

  ngOnInit(): void {
    this.Email_verify = sessionStorage.getItem('reset_email')
    this.Email_verification_code = sessionStorage.getItem('Email_Verification_code')
    console.log("Email",this.Email_verify)
    console.log("Email-verification_code",this.Email_verification_code)
    this.route.queryParams.subscribe((params:any) => {
      this.param = params['code'];
      console.log("Header parameter ",this.param)
      // this.service.SendEmailVerification(this.param).subscribe((res:any)=>{
      //   this.EmailVeriricationText = res.status.message;
      // })
  });
    let obj={
      "email":this.Email_verify,
      "email_verification_code" :this.param
    }
    this.authService.submitPasswordForm1(obj).subscribe((res:any)=>{
      console.log("Email and Code from API :-",res)
    })
    
  }

  register(formValue){
    // console.log("formValue=> ",formValue)
    console.log("the email :-",this.Email_verify)
    let submitobj ={
      "email":document.cookie.split('=')[1],
      "code": this.param,
      "password":this.registrationForm.value['password'],
      "password_confirmation":this.registrationForm.value['confirm_password'],
    }
    console.log("object of reset password api :-",submitobj)
    this.authService.submitPasswordForm2(submitobj).subscribe((res:any)=>{
      console.log("Register Form Response",res);

      if(res.status.code === 200){
          this.toastr.success(res.status.message);
          this.router.navigateByUrl('/login');   
          console.log (res.status.message) 
        }
      else if (res.status.code === 400){
          this.toastr.error(res.status.error_details);
        }  
      else if (res.status.code === 401){
        this.toastr.error(res.status.error_details);
      }
      else if (res.status.code === 403){
        let validation_error = res.status.validation_errors;
         console.log(res.status.validation_errors)
        validation_error.forEach(element => {
          if(element.field == 'password') {
            this.toastr.error(element.message);
          }

          else if(element.field == 'password_confirmation') {
            this.toastr.error(element.message);
          }

         

         

        })
        


      }
    })
  }




}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'dateFormatter'
})
export class DateFormatterPipe implements PipeTransform {

  transform(value: any, args: any, locale?: string): any {
    let date = moment(value).locale(locale ? locale : 'ar');
    return date.format(args);
  }

}

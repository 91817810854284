<div class="full-width">
    <div class="page-title">
      <div class="container">
        <h3>
            نموذج طلب منح من صندوق العناية بالقرآن الكريم
        </h3>
        </div>
    </div>
  </div>

<div class="container">  
  <div class="talabat-page">
    <div class="row">
      <div class="table-heading"><span> رقم الطلب :&nbsp;{{grant.id}}</span><span>التاريخ:
          &nbsp;{{grant.created_at | dateFormatter: 'DD-MM-yyyy' : 'ar'}}</span></div>
      <!-- <div class="col-md-8 col-sm-8 col-xs-8"> -->
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">اسم الجهة</span>
        </div>
        <label class="form-control">{{grant.name}}</label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">اسم البرنامج أو المشروع</span>
        </div>
        <label class="form-control">{{grant.project_name}}</label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">المسار</span>
        </div>
        <label class="form-control">{{getSelectedValueName('path', grant.path_id)}}</label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">النطاق</span>
        </div>
        <label class="form-control">{{getSelectedValueName('domain', grant.domain_id)}}</label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">الوصف</span>
        </div>
        <label class="form-control" [innerHTML]="grant.description"></label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">مكان التنفيذ</span>
        </div>
        <label class="form-control" [innerHTML]="grant.location"></label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">الأثر</span>
        </div>
        <label class="form-control" [innerHTML]="grant.effect"></label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">الهدف العام</span>
        </div>
        <label class="form-control" [innerHTML]="grant.general_goal"></label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">الأهداف التنفيذية</span>
        </div>
        <label class="form-control" [innerHTML]="grant.operational_goals"></label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">النطاق الزمني من</span>
        </div>
        <label class="form-control">{{grant.date_from}}</label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">النطاق الزمني الى</span>
        </div>
        <label class="form-control">{{grant.date_to}}</label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">المطاق المالي</span>
        </div>
        <label class="form-control">{{grant.amount}}</label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">اسم البنك</span>
        </div>
        <label class="form-control">{{getSelectedValueName('bank', grant.grant_bank_id)}}</label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">الحساب البنكى</span>
        </div>
        <label class="form-control">{{grant.bank_account_number}}</label>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">المخرجات</span>
        </div>
        <label class="form-control" [innerHTML]="grant.outputs"></label>
      </div>
      <!-- <table class="table table-bordered">
          <tbody>
            <tr>
              <td><span class="font--bold color--main">أسم الجهة</span></td>
              <td><span class="font-500">{{grant.name}}</span></td>
            </tr>
  
            <tr>
              <td><span class="font--bold color--main">اسم البرنامج أو المشروع</span></td>
              <td><span class="font-500">{{grant.project_name}}</span></td>
            </tr>
  
            <tr>
              <td><span class="font--bold color--main">المسار</span></td>
              <td><span class="font-500">{{getSelectedValueName('path', grant.path_id)}}</span></td>
            </tr>
  
            <tr>
              <td><span class="font--bold color--main">النطاق</span></td>
              <td><span class="font-500">{{getSelectedValueName('domain', grant.domain_id)}}</span></td>
            </tr>
  
            <tr>
              <td><span class="font--bold color--main">الوصف</span></td>
              <td><span class="font-500">{{grant.description}}</span></td>
            </tr>
  
            <tr>
              <td><span class="font--bold color--main">مكان التنفيذ</span></td>
              <td><span class="font-500">{{grant.location}}</span></td>
            </tr>
  
            <tr>
              <td><span class="font--bold color--main">الأثر</span></td>
              <td><span class="font-500">{{grant.effect}}</span></td>
            </tr>
  
            <tr>
              <td><span class="font--bold color--main">الهدف العام</span></td>
              <td><span class="font-500">{{grant.general_goal}}</span></td>
            </tr>
  
            <tr>
              <td><span class="font--bold color--main">الأهداف التنفيذية</span></td>
              <td><span class="font-500">{{grant.operational_goals}}</span></td>
            </tr>
  
            <tr>
              <td><span class="font--bold color--main">المخرجات</span></td>
              <td><span class="font-500">{{grant.outputs}}</span></td>
            </tr>
  
            <tr>
              <td><span class="font--bold color--main">النطاق الزمني من</span></td>
              <td><span class="font-500">{{grant.date_from}}</span></td>
            </tr>
  
            <tr>
              <td><span class="font--bold color--main">النطاق الزمني إلى</span></td>
              <td><span class="font-500">{{grant.date_to}}</span></td>
            </tr>
  
            <tr>
              <td><span class="font--bold color--main">النطاق المالي</span></td>
              <td><span class="font-500">{{grant.amount}}</span></td>
            </tr>
  
            <tr>
              <td><span class="font--bold color--main">اسم البنك</span></td>
              <td><span class="font-500">{{getSelectedValueName('bank', grant.grant_bank_id)}}</span></td>
            </tr>
  
            <tr>
              <td><span class="font--bold color--main">الحساب البنكى</span></td>
              <td><span class="font-500">{{grant.bank_account_number}}</span></td>
            </tr>
          </tbody>
        </table> -->
      <!-- </div> -->
    </div>
    <div style="margin: 1rem 0;" class="row">
      <button (click)="printFile(grant.id)" class="btn step-btn">طباعه الطلب</button>
      <button (click)="goToOrders()" class="btn step-btn">  الرجوع الي صفحة المستخدم</button>
    </div>
  </div>


</div>


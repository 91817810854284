import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { HttpClient } from '@angular/common/http';



declare var $:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'etaam-website';
    

  constructor(private translateService: TranslateService, 
              private router: Router,
              private http: HttpClient) {
    if(sessionStorage.getItem('etaam_lang'))  translateService.setDefaultLang(sessionStorage.getItem('etaam_lang'))
    else translateService.setDefaultLang('ar');

    this.router.events.subscribe(res => {
      if(res instanceof(NavigationEnd)) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    })





  }
  
  ngOnInit() {
    // this.http.get('https://jsonplaceholder.typicode.com/comments')
    //   .subscribe((r) => {
    //     console.log(r);
    //   });
  }





}

<div class="home">
    <section class="slider container">
        <div class=" swiper">
            <div class="swiper-container" *ngIf="slides.length" [swiper]="config">
                <div class="swiper-wrapper">
                    <div *ngFor="let slide of slides; index as i" class="swiper-slide">
                        
                        <div class="slide-img">
                            <img *ngIf="slide.type == 'image' " [src]="slide.image">
                         
                           
                                
                            <iframe *ngIf="slide.type == 'video'" width="100%" height="100%" [src]="sanitizer.bypassSecurityTrustResourceUrl(formatVideoURL(slide.link))" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            
                              
                            <!-- <div *ngIf="slide.type == 'video'" [innerHtml]="sanitizer.bypassSecurityTrustResourceUrl(yt_iframe_html)"></div> -->
                            <!-- <div *ngIf="slide.type == 'video'" src="https://vimeo.com/441479107/embed"></div> -->
                            <!-- <iframe *ngIf="slide.type == 'video'" width="100%" height="100%"src="https://player.vimeo.com/video/441479107" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

                        </div>

                        <!-- <div class="slide-text text-left">
                            <h2> {{slide.title}} </h2>
                            <p> {{slide.description}}</p>
                            <a (click)="goTo(slide.link)" class="btn-custom-sec rounded20"> اقرأ المزيد</a>
                        </div> -->
                    </div>
    
                    
                </div>
                <!-- <div class="swiper-scrollbar" [hidden]="config.scrollbar === false"></div> -->
                <div class="swiper-pagination" slot="pagination" ></div>
    
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
            </div>
        </div>

    </section>

      <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/1ozGKlOzEVc" frameborder="0" allowfullscreen></iframe> -->

    <section class="about">
        <div class="container">
            <div class="row">
                <div class="col-md-2 col-sm-3 col-xs-12 text-center" style="float:right">
                    <img [src]="whoWeAre?.photo" class="img img-reponsive" alt="Cinque Terre">
                </div>
                <div class="col-md-9 col-sm-9 col-xs-12 pull-right text-left desc">
                    <h3 class="main-title"> {{whoWeAre?.name}}</h3>
                    <p>
                        {{whoWeAre?.content}}
                    </p>
                    <br />
                    <a class="btn-custom" routerLink="/about"> {{'HOME.READ_MORE'|translate}}</a>
                </div>
            </div>
        </div>
    </section>

    <section class="initiative">
        <div class="container">
            <h3 class="main-title text-center">{{'HEADER.INITIATIVES'|translate}} </h3>
            <p class="text-center">
                "خيركم من تعلم القرآن وعلمه"
            </p>
            <div class="container">
                <owl-carousel-o [options]="customOptions">
                    <ng-template *ngFor="let initiative of initiatives" carouselSlide>
                        <div (click)="openInitiative(initiative.id)" class="feature rounded20">
                            <div class="initiative-img">
                                <img [src]="initiative.image" class="img-responsive" alt="">
                            </div>
                            <div class="initiative-desc">
                                <h4 class="text-center ">{{initiative.name}}</h4>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>

                <div class="clearfix"></div>
                <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                        <button [routerLink]="['/initiatives']" class="btn-custom" style="margin-top: 50px;">عرض كل المبادرات</button>

                    </div>
                </div>
                
            </div>
        </div>
    </section>

    <section class="bank">
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-sm-7 col-xs-12 no-padding bank-info pull-left">
                    <div class="col-md-3 col-sm-4 col-xs-12 pull-left text-center">
                        <img src="{{maincardimg}}" class="img"  alt="">
                    </div>
                    <div class="col-md-9 col-sm-8 col-xs-12 pull-right text-left desc">
                        <p [innerHTML]="maincardinfo">
                        </p>
                    </div>
                </div>


                <div class="col-md-4 col-sm-5 col-xs-12 pull-right text-left statistics">
                    <div style="display: flex;">
                        <span class="circle_dot"><i class="fa fa-chart-line" style="margin: 6px 4px 5px 5px;"></i></span>
                        <h4 class="sec-title"> إحصائيات الجمعيات</h4>
                    </div>
                    <div class="items">
                        <div class="item" *ngFor="let item of associationlist">
                            <span class="icon"> <i class="fa fa-arrow-up"></i></span>
                            {{item.name}}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>

    <section class="home-news">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h3 class="main-title text-center"> {{'HOME.LATEST_NEWS'|translate}} </h3>
                </div>
            </div>
            <div class="row" style="clear: both;">
                
                <div class="col-md-8 col-sm-6 col-xs-12 bottom-margin pull-left">
                    <div class="row">
                        <div *ngFor="let article of news | slice:0:4 " class="col-md-6 col-sm-6 col-xs-12 bottom-margin pull-left">
                            <div class="home-article">
                                <a [routerLink]="['/articles', article.id]" class="cover-img"
                                    [ngStyle]="{'background-image': 'url('+ article?.image +')'}">
                                    <div class="home-article-desc text-left">
                                        <h4 class="card-title"> {{article.title}}</h4>
                                        <small class="p-date">{{article.created_at}}</small>
                                        <!-- <p class="card-paragraph">{{article.content}}</p> -->
                                         <p class="card-paragraph" [innerHTML]="article.content"></p>

        
                                    </div>
                                </a>
        
                            </div>
                        </div>
                    </div>
                    <br>
                    
                   
                </div>

                <div class="col-md-4 col-sm-6 col-xs-12 bottom-margin pull-left">
                    <a class="twitter-timeline"
                        href="https://twitter.com/quran5051"
                        data-width="350"
                        data-height="514"
                        data-lang="ar"
                        data-dnt="true"
                        content="on"
                        lang="ar" 
                        dir="rtl"
                        >
                        Tweets by @quran5051
                    </a>

                </div>
            
                
                <!-- end of home article-->
            </div>

            <div class="row">
                <div class="col-xs-12 text-center">
                    <button class="btn-custom" [routerLink]="['/articles']">
                        {{'HOME.VIEW_ALL_ARTICLES'|translate}}</button>
                </div>
            </div>
            <br>
            
          
        </div>
    </section>

    <section class="home-events">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h3 class="main-title text-center"> الفعاليات </h3>
                </div>
            </div>
            <div class="row">
                <div *ngFor="let event of events | slice:0:6" class="col-md-4 col-sm-12 col-xs-12">
                    <a [routerLink]="['/event', event.id]">
                        <div class="card">
                            <div class="card-img-top" [ngStyle]="{'background-image': 'url('+ event?.image +')'}"></div>
                            <div class="card-body ">
                                <div class="row">
                                    <div class="col-md-3 col-sm-4 xol-xs-4">

                                        <time datetime="" class="icon">
                                            <em>{{event.created_at | dateFormatter: 'ddd':'ar'}}</em>
                                            <strong>{{event.created_at | dateFormatter: 'MMM':'ar'}}</strong>
                                            <span>{{event.created_at | dateFormatter: 'D':'ar'}}</span>
                                        </time>
                                        
                                    </div>
                                    <div class="col-md-9 col-sm-9 col-xs-9 ">
                                        <div class="event-desc text-left">
                                            <h4 class="card-title">{{event?.title}}</h4>
                                            <p> <i class="fa fa-map-marker"></i>&nbsp; {{event?.address}} </p>
                                            <a class="more"
                                                [routerLink]="['/event', event.id]">{{'HOME.VIEW_DETAILS'|translate}}
                                                <i class="fa fa-arrow-circle-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <br>
            <br>
            <div class="row">
                <div class="col-xs-12 text-center">
                    <button class="btn-custom" [routerLink]="['/event']">
                        {{'HOME.VIEW_ALL_EVENTS'|translate}}</button>
                </div>
            </div>
        </div>
    </section>
    <!-- end of home events-->

    <section class="pickup">
        <div class="full-width ">
            <div class="container position-relative">
                <div class="pickup-desc  text-center row">

                    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                        <h3 class="main-title" style="color: white;"> استمع للقرآن الكريم </h3>
                        <br>
                        <audio id="audio" tabindex="0" controls="" type="audio/mpeg" style="border-radius: 20px;background-color: #f1f3f4; max-width: 100%;">

                            <source type="audio/mp3"
                                src="https://download.tvquran.com/download/tilawa_01/TvQuran.com__034.mp3">
                            عفوا، المتصفح المستخدم قديم لا يدعم الصوتيات
                        </audio>

                    </div>
                </div>
            </div>
        </div>
    </section>

</div>
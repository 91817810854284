import { Component, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';
@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
  // host: {
  //   '(document:click)': 'onClick($event)',
  //  }
})
export class AuthLayoutComponent implements OnInit {
  registrationRef: Boolean = false;

  opened = true;
  register_popup = false;
  myRig = false;



  @Output() closeLoginPopup = new EventEmitter();
  opened2: boolean;
  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    // window.addEventListener('scroll', this.noScroll);
  }

  close(event) {
    this.opened = false;
    event.preventDefault();
    window.removeEventListener('scroll', this.noScroll);
    this.valueChanged();
  }

  valueChanged() {
    this.opened = false;
    this.closeLoginPopup.emit(this.opened);
    window.removeEventListener('scroll', this.noScroll);

  }

  noScroll() {
    window.scrollTo(0, 0);
  }

  openRegistrationForm(event) {
    this.registrationRef = event;
    this.opened = false;
    window.removeEventListener('scroll', this.noScroll);

  }

  closePop() {
    this.opened = false;
    window.removeEventListener('scroll', this.noScroll);

  }

//  onClick(event) {

//   if (!this.elementRef.nativeElement.contains(event.target)) // or some similar check
//     // alert('clicked')
//     //doSomething();
//   //  this.opened2 = false;

//  }


}

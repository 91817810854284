

    <div [className]="('DIR'|translate) === 'rtl' ? 'rtl': 'ltr'">
        <app-etaam-header></app-etaam-header>
        <app-popup></app-popup>
        <div class="page">
            <app-loader></app-loader>
            <!-- <div>
             <button (click)="callApi()">Click</button>
            </div> -->
            <router-outlet></router-outlet>
        </div>
        <app-etaam-footer></app-etaam-footer>
    </div>



import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './general-pages/pages/about/about.component';
import { ContactComponent } from './general-pages/pages/contact/contact.component';
import { PartnersComponent } from './general-pages/pages/partners/partners.component';
import { CeoMessageComponent } from './general-pages/pages/ceo-message/ceo-message.component';
import { TeamComponent } from './general-pages/pages/team/team.component';
import { LibraryComponent } from './general-pages/pages/library/library.component';
import { HomeComponent } from './general-pages/pages/home/home.component';
import { RegistrationComponent } from './authentication/pages/registration/registration.component';
import { FriendlyWebsiteComponent } from './general-pages/pages/friendly-website/friendly-website.component';

import {ResetPasswordComponent}  from './authentication/pages/reset-password/reset-password.component';
import { ResetPassword1Component } from './authentication/pages/reset-password1/reset-password1.component';
import {ResetPassword2Component} from  './authentication/pages/reset-password2/reset-password2.component';

import {RegisterVerificationComponent} from './register-verification/register-verification.component';

import {ProductsComponent} from './general-pages/pages/products/products.component';
import {TalabatComponent} from './general-pages/pages/talabat/talabat/talabat.component';

import {PrivacyPolicyComponent}from './general-pages/pages/privacy-policy/privacy-policy.component';
import {TermsComponent} from './general-pages/pages/terms/terms.component';

import { LoginPageComponent } from './authentication/pages/login-page/login-page.component';
import { OrderByIdComponent } from './general-pages/pages/order-by-id/order-by-id.component';
import { SearchComponent } from './general-pages/pages/search/search.component';
// import { UserAreaIndexComponent } from './user-area/pages/user-area-index/user-area-index.component';
// import { FinancialReportsComponent } from './user-area/pages/financial-reports/financial-reports.component';
// import { SmsServicesComponent } from './user-area/pages/sms-services/sms-services.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  {path:'home',component:HomeComponent},
  {path:'registration',component:RegistrationComponent},
  {path:'Email-Verification',component:RegisterVerificationComponent},
  {path:'login',component:LoginPageComponent},
  {path:'search',component:SearchComponent},


  {path:'reset-password',component:ResetPasswordComponent},
  {path:'reset-password1',component:ResetPassword1Component},
  {path:'reset-password2',component:ResetPassword2Component},

  
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'Team', component: TeamComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'partners', component: PartnersComponent },
  { path: 'ceo_msg', component: CeoMessageComponent },
  { path: 'team', component: TeamComponent },
  { path: 'library', component: LibraryComponent },
  {path:'friendly-websites',component:FriendlyWebsiteComponent},
  {path:'products',component:ProductsComponent},
  {path:'orders/:id',component:OrderByIdComponent},

  {path:'privacy-policy',component:PrivacyPolicyComponent},
  {path:'terms&conditions',component:TermsComponent},

  // {path:'user-area-index', component:UserAreaIndexComponent},
  // {path:'user-area-index/financial-reports', component:FinancialReportsComponent},
  // {path:'user-area-index/sms-services', component:SmsServicesComponent},

  {
    path: 'event',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
  },

  {
    path: 'articles',
    loadChildren: () => import('./articles/articles.module').then(m => m.ArticlesModule)
  },

  {
    path: 'sponsor',
    loadChildren: () => import('./sponsor/sponsor.module').then(m => m.SponsorModule)
  },

  {
    path: 'opportunities',
    loadChildren: () => import('./opportunities/opportunities.module').then(m => m.OpportunitiesModule)
  },
  {
    path: 'associations',
    loadChildren: () => import('./memorize-quran/memorize-quran.module').then(m => m.MemorizeQuranModule)

  },
  {
    path: 'initiatives',
    loadChildren: () => import('./initiatives/initiatives.module').then(m => m.InitiativesModule)

  },

  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then(m => m.SupportModule)

  },

  {
    path: 'user-area',
    loadChildren: () => import('./user-area/user-area.module').then(m => m.UserAreaModule)
  },
  {
    path: 'recruitment',
    loadChildren: () => import('./recruitment/recruitment.module').then(m => m.RecruitmentModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}


<div class="full-width">
    <div class="page-title">
        <div class="container">
            <h3>
            المنتجات
        </h3>
        </div>
    </div>
</div>
<div class="support page">
    <div class="container" >
        <div class="row bank" style="background: #f0f0f0;">
            <div class="col-md-2 col-sm-2 col-xs-3 img-position">
                <img src="{{maincardimg}}" class="img img-reponsive" alt="Cinque Terre">
            </div>
            <div class="col-md-10 col-sm-10 col-xs-9 pull-right text-left desc">
                <p [innerHTML]="maincardinfo">
                    <!-- {{maincardinfo}} -->
                    <b>   </b>
                    <br/>
                    <br/>
                    <!-- ومن كافة البنوك بحساب أبيان -->
                    <b>  </b>
                </p>
            </div>
        </div>
        
    </div>

    <div class="container">
        <div class="row bordered">
            <div class="col-md-4 col-sm-6 col-xs-12" *ngFor="let item of productslist" style="margin-bottom: 15px;">
                <div class="card" style="height: 100%;">
                    <img class="card-img" src="{{item.image}}">
                    <p class="card-title">{{item.title}}</p>
                </div>
            </div>
        </div>
        
    </div>



</div>



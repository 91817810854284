import { Component, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {VerificationService} from '../services/verification.service';

@Component({
  selector: 'app-register-verification',
  templateUrl: './register-verification.component.html',
  styleUrls: ['./register-verification.component.scss']
})
export class RegisterVerificationComponent implements OnInit {
  mylogin=false;

  token;
  registrationRef: Boolean = false;

  opened ;
  register_popup = false;
  myRig = false;

  param;
  EmailVeriricationText;

  @Output() closeLoginPopup = new EventEmitter();
  opened2: boolean;
  constructor(private elementRef: ElementRef, private service:VerificationService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.noScroll);
    this.opened = false;
  //   if (this.route.snapshot.queryParams['cat_id']) {
  //     this.service.SendEmailVerification(this.route.snapshot.queryParams['cat_id']).subscribe((res:any)=>{
  //     this.EmailVeriricationText = res.status.message;
  //   })
  // }

    this.route.queryParams.subscribe((params:any) => {
      this.param = params['code'];
      console.log("Header parameter",this.param)
      this.service.SendEmailVerification(this.param).subscribe((res:any)=>{
        this.EmailVeriricationText = res.status.message;
      })
  });
    

  }

  close(event) {
    this.opened = false;
    event.preventDefault();
    window.removeEventListener('scroll', this.noScroll);
    this.valueChanged();
  }
  valueChanged() {
    this.opened = false;
    this.closeLoginPopup.emit(this.opened);
  }

  noScroll() {
    window.scrollTo(0, 0);
  }

  openRegistrationForm(event) {
    this.registrationRef = event;
  }

  closePop() {
    this.opened = false;
 }

 ClickedOut(event) {
    if(event.target.className === "hover_bkgr_fricc") {
      this.opened = false;
      console.log(event)
    } 
 }

 viewLogin()
 {

   if(this.opened==false)
   {
     this.opened=true;
   }
 
 }
}

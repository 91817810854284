<div class="full-width">
    <div class="page-title">
        <div class="container">
            <h3>{{privacypolicy.name}}</h3>
        </div>
    </div>
</div>

<div class="container">
    <div class="card">
        <div class="row">
            <!-- <div class="col-md-3 col-sm-3 col-xs-12 pull-left">
                <img class="img" src="{{privacypolicy.photo}}">
            </div> -->
            <div class="col-md-12 col-sm-12 col-xs-12 pull-left text-left">
                
                <p class="paragraph" [innerHTML]="privacypolicy.content"> </p>
            </div>
    
        </div>
    </div>
    

</div>
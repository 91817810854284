<header class="shadow">
    <div class="full-width">
        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img class="logo" src="assets/images/logo.PNG">
                <span class="slogan desktop-only">
                    <img class="slogan1" src="assets/images/slo1.png">
                    <img class="slogan2" src="assets/images/slo2.png">
                </span>
                <img class="logo desktop-only" src="assets/images/slo3.png">
                <img class="logo desktop-only" src="assets/images/slo4.jpg">
            </a>
            <div class="mobile-only pull-right">
                <div class="nav-item dropdown pull-right" *ngIf="token != null ">
                    <a class="nav-link notification-button" href="#" id="notification" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-bell"></i>
                        <span class="num">{{notification_count | numberFormatter}}</span>
                    </a>
                    <div class="dropdown-menu notification-list" aria-labelledby="notification">
                        <li *ngFor="let notificationItem of notificationlist">
                            <a class="nav-link dropdown-item {{notificationItem.read_at ? 'notification-read' : ''}}" routerLink="user-area/orders" id="notifi" (click)="readselected(notificationItem.id)">
                                <span class="float-right">
                                    {{notificationItem.notification_text}}
                                </span>
                                
                                <small class="float-right time">
                                     {{notificationItem.since}} 
                                </small>
                            </a>
                        </li>
                    </div>
                </div>

                <div class="searchbar pull-right">
                     <span class="search-button">
                        <div class="nav-link bold search-toggle bold"  aria-hidden="true" (click)="clickEvent()">
                            <i class="fa fa-search"></i>
                        </div>
                     </span>
                     <div class="togglesearch" [ngClass]="status ? 'show' : 'hide'">
                         <div class="container">
                            <input type="text" (keyup.enter)="search()" placeholder="البحث" [(ngModel)]="searchQuery"  [ngModelOptions]="{standalone: true}"  >
                            <!-- <input type="button" value="بحث"/> -->
                            <button class="search-btn" (click)="search()">بحث </button>
                        </div>
                    </div>
                </div>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse pull-right text-right" id="navbarNavDropdown">
                <ul class="navbar-nav">
                    <li class="mobile-only">
                        <hr>
                        <a class="navbar-brand" data-toggle="collapse" data-target=".navbar-collapse.show" routerLink="/">
                            <span class="slogan">
                                <img class="slogan1" src="assets/images/slo1.png">
                                <img class="slogan2" src="assets/images/slo2.png">
                            </span>
                            <img class="logo" src="assets/images/slo3.png">
                            <img class="logo" src="assets/images/slo4.jpg">
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="FixTheStyle()"  routerLink="home" data-toggle="collapse" data-target=".navbar-collapse.show"  routerLinkActive="active-link">{{'HEADER.HOME'|translate}} </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="FixTheStyle()" routerLink="about" data-toggle="collapse" data-target=".navbar-collapse.show"  routerLinkActive="active-link">
                            {{'HEADER.ABOUT'|translate}}
                        </a>
                    </li>

                    <!-- <li class="nav-item dropdown pull-right">
                        <a class="nav-link dropdown-toggle" href="#" id="media" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{'HEADER.MEDIA_CENTER'|translate}}
                        </a>
                        <div class="dropdown-menu" aria-labelledby="media">
                           
                        </div>
                    </li> -->

                    <li class="nav-item">
                        <a class="nav-link" (click)="FixTheStyle()" routerLink="articles" data-toggle="collapse" data-target=".navbar-collapse.show"  routerLinkActive="active-link">الأخبار</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" (click)="FixTheStyle()" routerLink="event" data-toggle="collapse" data-target=".navbar-collapse.show"  routerLinkActive="active-link">الفعاليات</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" (click)="FixTheStyle()" routerLink="initiatives" data-toggle="collapse" data-target=".navbar-collapse.show"  routerLinkActive="active-link">مبادرات الصندوق</a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" (click)="FixTheStyle()" routerLink="support" data-toggle="collapse" data-target=".navbar-collapse.show"  routerLinkActive="active-link">دعم الصندوق</a>
                    </li>

                    <!-- <li class="nav-item">
                        <a class="nav-link" routerLink="associations"  routerLinkActive="active-link">جميعات تحفيظ القرآن</a>
                    </li> -->

                    <li class="nav-item">
                        <a class="nav-link" (click)="FixTheStyle()" routerLink="/recruitment" data-toggle="collapse" data-target=".navbar-collapse.show"  routerLinkActive="active-link">التوظيف</a>
                    </li>

                    <!-- <li class="nav-item">
                        <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"   routerLinkActive="active-link">الموردين </a>
                    </li> -->

                    <!-- <li class="nav-item">
                        <a class="nav-link" routerLink="/orders" *ngIf="token != null"  routerLinkActive="active-link">  الطلبات</a>
                    </li> -->

                    <li class="nav-item">
                        <a class="nav-link" (click)="FixTheStyle()" [routerLink]="['contact']" data-toggle="collapse" data-target=".navbar-collapse.show"  routerLinkActive="active-link">{{'FOOTER.CONTACT_US'|translate}}</a>
                    </li>

                    <li class="nav-item" *ngIf="token === null ">
                        <a class="nav-link btn-custom" (click)="viewLogin()" data-toggle="collapse" data-target=".navbar-collapse.show" style="cursor: pointer;"  routerLinkActive="active-link">
                             <!-- {{'HEADER.LOGIN'|translate}} -->
                             {{'HEADER.beneficiary-services'|translate}}
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="token != null ">
                        <a class="nav-link" (click)="Logout()" data-toggle="collapse" data-target=".navbar-collapse.show" style="cursor: pointer;"  routerLinkActive="active-link">
                             {{'HEADER.LOGOUT'|translate}}
                        </a>
                    </li>

                    <li class="nav-item" *ngIf="token != null ">
                        <a class="nav-link btn-custom" style="cursor: pointer;" data-toggle="collapse" data-target=".navbar-collapse.show" [routerLink]="['user-area']">{{'HEADER.beneficiary-services'|translate}}</a>
                    </li>

                    <li class="nav-item dropdown pull-right desktop-only" *ngIf="token != null ">
                        <a class="nav-link notification-button" href="#" id="notification" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-bell"></i>
                            <span class="num">{{notification_count | numberFormatter}}</span>
                        </a>
                        <div class="dropdown-menu notification-list" aria-labelledby="notification">
                            <li *ngFor="let notification of notificationlist" >
                                <a class="nav-link dropdown-item  {{notification.read_at ? 'notification-read' : ''}}"  id="notifi" (click)="readselected(notification)">
                                    <span class="float-right">
                                        {{notification.notification_text}}
                                    </span>
                                    
                                    <small class="float-right time">
                                         {{notification.since}} 
                                    </small>
                                </a>
                            </li>

                            <!-- <li>
                                <a class="nav-link dropdown-item" routerLink="">
                                    <span class="float-right">
                                        نص الاشعار
                                    </span>
                                    
                                    <small class="float-right time">
                                        منذ 3 ساعات
                                    </small>
                                </a>
                            </li> -->

                        </div>
                    </li>

                    <div class="searchbar desktop-only">
                         <span class="search-button">
                            <div class="nav-link bold search-toggle bold search-btn"  aria-hidden="true" (click)="clickEvent()">
                                <i class="fa fa-search"></i>
                            </div>
                         </span>
                         <div class="togglesearch" [ngClass]="status ? 'show' : 'hide'">
                             <div class="container">
                                <input type="text" (keyup.enter)="search()" placeholder="البحث" [(ngModel)]="searchQuery"  [ngModelOptions]="{standalone: true}"  >
                                <!-- <input type="button" value="بحث"/> -->
                                <button class="search-btn" (click)="search()">بحث </button>
                            </div>
                        </div>
                    </div>

                </ul>
            </div>
        </nav>
    </div>
</header>

<app-auth-layout *ngIf="mylogin" (closeLoginPopup)='closeLogin($event)'></app-auth-layout>
<!-- <app-donate-popup *ngIf="myprofile"> </app-donate-popup> -->

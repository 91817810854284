<div class="full-width">
    <div class="page-title">
        <div class="container">
            <h3>
                {{'LIBRARY.LIBRARY'|translate}}
            </h3>
        </div>
    </div>
</div>

<div class="magazines internal-page">
    <div class="container">
        <div class="row filter">
            <form class="custom full-width form-padding">
                <div class="col-md-10 col-sm-10 col-xs-10 pull-left no-padding-r">
                    <div class="form-group more-padding">
                        <label class="pull-left">{{'LIBRARY.CAT'|translate}}</label>
                        <select>
                            <option value="1">All</option>
                            <option value="1">إصدارات إطعام </option>
                            <option value="2"> مكين </option>
                            <option value="3"> الحوكمة </option>
                            <option value="3">السياسات والتنظيمات</option>
                            <option value="3">الموظفون</option>
                            <option value="3">شركاء حفظ النعمة</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2 col-sm-2 col-xs-2 pull-left pull-left no-padding-l">
                    <button class="btn-custom full-width"> <i class="fa fa-search"></i> <br> {{'LIBRARY.FILTER'|translate}} </button>
                </div>
            </form>
        </div>
        <div class="row cards">
            <div  *ngFor="let item of libraries | paginate: { itemsPerPage: 3, currentPage: page}" class="col-md-4 col-sm-6 col-xs-12">
                <app-library-card [library]="item"></app-library-card>
            </div>


        </div>
        <div class="has-text-centered">
        <pagination-controls (pageChange)="page = $event"></pagination-controls>
    </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';
@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(private baseHttpService: BaseHttpService) { }

  getEvents(currentPage = 1) {
    let baseEventsObject = {
      "module_id": 462
    }
    return this.baseHttpService.post('/cms', baseEventsObject, {
      params: {
        page: currentPage
      }
    });
  }

  getEventByID(eventID) {
    let eventByIDObject = {
      "id": eventID,
      "module_id": 462
    }
    return this.baseHttpService.post('/cms/show', eventByIDObject, {});
  }

  getSearchEvents(currentPage,search) {
    let baseEventsObject = {
      "application_id": 25,
      "lang_id": 2,
      "module_id": 462,
      "title":search
    }
    return this.baseHttpService.post('/cms', baseEventsObject, {
      params: {
        page: currentPage
      }
    });
  }

  getAdvancedSearchEvents(currentPage,searchData){
    let baseEventsObject = {
      "application_id": 25,
      "lang_id": 2,
      "module_id": 462,
      "title":searchData.title,
      "content" :searchData.content,
      "from_date": searchData.from_date,
      "to_date": searchData.to_date
    }
    return this.baseHttpService.post('/cms', baseEventsObject, {
      params: {
        page: currentPage
      }
    });
  }




}

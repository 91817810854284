import { Component, OnInit, Input } from '@angular/core';
import {Event} from '../../models/event';

@Component({
  selector: 'app-card-event',
  templateUrl: './card-event.component.html',
  styleUrls: ['./card-event.component.scss']
})
export class CardEventComponent implements OnInit {
  @Input() eventDetails;
  myprofile=false;

  constructor() { }

  ngOnInit(): void {
  }


  closeProfile(val)
  {
    console.log("val >>>>",val);
    this.myprofile=val;
    console.log("profile >>>>",this.myprofile);

  }
  viewProfile()
  {
    if(this.myprofile==false)
    {
      this.myprofile=true;
    }

  }
}

import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class AssociationService {

  constructor(private baseHttpService: BaseHttpService) { }

  getDistricts() {
    return this.baseHttpService.post('/association_districts', {
      "module_id": 494,
      "lang_id": 1
    }, {})
  }

  getAssociationsByDistrictID(districtID) {
    return this.baseHttpService.post('/get_associations', {
      "module_id": 494,
      "lang_id": 1,
      "district_id": districtID
    }, {})
  }

  getAllAssociations() {
    return this.baseHttpService.post('/associations', {
      "module_id": 494,
      "lang_id": 1
    }, {})
  }

  showAssociationByID(associationID) {
    return this.baseHttpService.post('/associations/show', {
      "module_id": 494,
      "lang_id": 1,
      "id": associationID
    }, {})
  }

  getSearchAssociation(currentPage = 1,search) {
    let baseEventsObject = {
      "module_id": 494,
      "name":search
    }
    return this.baseHttpService.post('/associations', baseEventsObject, {
      params: {
        current_page: currentPage
      }
    });
  }

  getAdvancedSearchAssociation(currentPage = 1,searchData){
    let baseEventsObject = {
      "module_id": 494,
      "name":searchData.name,
      "content" :searchData.content,
      "from_date": searchData.from_date,
      "to_date": searchData.to_date
    }
    return this.baseHttpService.post('/associations', baseEventsObject, {
      params: {
        current_page: currentPage
      }
    });
  }



  
}

<div class="full-width">
    <div class="page-title">
        <div class="container">
            <h3> 
                كود التأكيد
            </h3>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col-md-8 col-sm-8 col-xs-12">
           <form>
               <div class="form-group">
                   <h2 class="main-title">{{EmailVeriricationText}}</h2>
               </div>
           </form>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-12">
            <button  (click)="viewLogin()"    class="btn btn-xs btn-custom new"  type="submit" style="margin-top: 260px;">{{'Login'|translate}}</button>
        </div>
    </div>
</div>

<div class="popup hover_bkgr_fricc" *ngIf="opened" (click)="ClickedOut($event)">
    <span class="close" (click)="close($event);valueChanged()">
        <i class="fa fa-times"></i>
    </span>
    <div class="content">
      <app-login-popup *ngIf="!registrationRef" (registrationRef)="openRegistrationForm($event)"></app-login-popup>
      <!-- <app-register-popup *ngIf="registrationRef"></app-register-popup> -->
    </div>
</div>
<div class="style" *ngIf="opened"></div>
import { Component, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { LoaderService } from '../../shared_services/loader.service';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;

  loading: boolean;


  constructor(private loaderService: LoaderService) {

    this.loaderService.isLoading.subscribe((v) => {
      // console.log(v);
      this.loading = v;
     });
   }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
// import { $ } from 'jquery';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { CommonGeneralService } from 'src/app/general-pages/pages/services/common-general.service';
import { SubscribeService } from '../../shared_services/subscribe.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery'
const VISITOR_COUNTER = 9;
@Component({
  selector: 'app-etaam-footer',
  templateUrl: './etaam-footer.component.html',
  styleUrls: ['./etaam-footer.component.scss']
})
export class EtaamFooterComponent implements OnInit {
  myprofile: boolean = false;
  contactData: any = {};
  SubscribeForm: FormGroup;
  SocialLinks;
  visitors: any;
  facebooklink;
  instagramlink;
  youtubelink;
  twitterlink;
  snapchatlink: any;


  constructor(private router: Router,
    private commonPagesService: CommonGeneralService,
    private subscribeseervicez: SubscribeService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService) {

    this.SubscribeForm = formBuilder.group({
      'email': ['', Validators.compose([Validators.required])],
    })

  }

  ngOnInit(): void {
    this.commonPagesService.getContactData().subscribe((res: any) => {
      this.contactData = res.content[0];
      console.log("footer data",this.contactData)
    })

    this.commonPagesService.getVisitors().subscribe((res: any) => {
      this.visitors = res.content.visits;
      setTimeout(() => {
        this.formatDigits();
      }, 1000);
    })

    this.commonPagesService.getFooterSocialLinks().subscribe((res: any) => {
      this.SocialLinks = res.content;
 
      // console.log("Social Links : ",this.SocialLinks)
    })

  }

  viewProfile() {
    if (this.myprofile == false) {
      this.myprofile = true;
    }

    window.scrollTo(0, 0);

  }

  closeProfile(val) {
    this.myprofile = val;

  }

  PostSubscribeEmail() {
    this.subscribeseervicez.PostKa2emaBaridaya(this.SubscribeForm.value).subscribe((res: any) => {
      // console.log("subscription res =====>",res)
      if (res.status.code === 200) {
        this.toastr.success(res.status.message)
        // console.log(res.status.message)
      }
      else if (res.status.code === 401) {
        this.toastr.error(res.status.error_details);
        // console.log(res.status.error_details)

      }
      else if (res.status.code === 400) {
        this.toastr.error(res.status.error_details);
        // console.log(res.status.error_details)
      }
      else if (res.status.code === 403) {
        this.toastr.error(res.status.validation_errors[0].message);
        // console.log(res.status.validation_errors[0].message)
      }
      else {
        this.toastr.error(res.status.error_details);
        // console.log(res.status.error_details)
      }

    })
  }

  formatDigits() {
    var text = $("#visitors").html();
    $("#visitors").html('');
    for (let i = 0; i <= text.length - 1; i++) {
      var html = text.substr(i, 1);
      var sp = "<span class='digit'>" + html
      sp += " </span> &nbsp;";
      $("#visitors").prepend(sp);
    }
    for (let j = 0; j <= VISITOR_COUNTER - text.length; j++) {
      var sp = "<span class='digit'>" + 0
      sp += " </span> &nbsp;";
      $("#visitors").append(sp);
    }
    /* var list = $('#visitors');
    var listItems = list.children('span');
    list.append(listItems.get().reverse()); */
  }
    


}

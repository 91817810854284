import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { PusherService } from 'src/app/services/pusher/pusher.service';
import { NotificationsService } from '../../../shared/shared_services/notifications.service';

import { EventsService } from '../../../events/services/events.service';
import { ArticlesService } from '../../../articles/services/articles.service';
import { InitiativesService } from '../../../initiatives/services/initiatives.service';
import * as $ from 'jquery'

@Component({
  selector: 'app-etaam-header',
  templateUrl: './etaam-header.component.html',
  styleUrls: ['./etaam-header.component.scss']
})
export class EtaamHeaderComponent implements OnInit {

  myprofile = false;
  mylogin = false;

  token;
  notificationlist: any[] = [];
  notification_count: number = 0;

  searchQuery: string;
  searchRes: any[] = [];
  searchSubscription: any;

  currentPage: number = 1;

  A5barsearchRes: any[] = [];
  Fa3eleyatsearchRes: any[] = [];
  MobadaratsearchRes: any[] = [];
  check_readnotification: any;


  constructor(private translateService: TranslateService,
    private toastr: ToastrService,
    private router: Router,
    private notifi: NotificationsService,
    private pusherService: PusherService,

    private a5barservice: ArticlesService,
    private fa3eleyatservice: EventsService,
    private mobadaratservice: InitiativesService

  ) { }

  ngOnInit(): void {
    this.pusherService.channel.bind('new-notification', data => {
      this.toastr.success(data.content.notification_text)
      // this.notificationsList.push(data);
    });

    this.token = localStorage.getItem('login_token')
    this.GetNotifications();

  }

  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }

  closesearch() {
    document.getElementById("togglesearch").style.display = "none";
  }

  closeProfile(val) {
    this.myprofile = val;

  }
  closeLogin(val) {
    this.mylogin = val;
    $('body').off();
  }
  viewProfile() {
    if (this.myprofile == false) {
      this.myprofile = true;
    }

  }
  viewLogin() {
    if (this.mylogin == false) {
      this.mylogin = true;
      setTimeout(() => {
        $('body').on('click', (event) => {
          if ($(event.target).closest('.popup.hover_bkgr_fricc').length == 0) {
            this.mylogin = false;
            $('body').off();
          }
        })
      }, 200);
    }

  }

  changeLang(lang) {
    this.translateService.setDefaultLang(lang);
    sessionStorage.setItem("etaam_lang", lang);
  }

  Logout() {
    localStorage.removeItem('login_token');
    localStorage.removeItem('login_assoc');
    localStorage.removeItem('association_id');
    this.toastr.success("لقد تم تسجيل الخروج بنجاح")
    window.location.replace('/home');



  }

  GetNotifications() {
    this.notifi.GetNotificationsList().subscribe((res: any) => {
      this.notificationlist = res.content;
      this.notificationlist.forEach(element => {
        if (!element.read_at) {
          this.notification_count += 1;
        }
      });
    })
  }

  public readselected(item) {
    document.getElementById("notifi").classList.toggle("read");
    if (item.displayed_at == null) {
      this.notifi.ReadNotificationList(item.id).subscribe((res: any) => {
        this.notification_count -= 1;
        this.router.navigate(['/user-area/orders']);
      })
    }
    else {
      this.router.navigate(['/user-area/orders']);
    }
  }

  search() {
    if (this.searchQuery) {
      this.status = false;
      this.router.navigate(['search'], {
        queryParams: {
          query: this.searchQuery
        }
      })
    } else {
      return;
    }
  }

  FixTheStyle(){
    $("body").removeClass(' nb-theme-default');
  }


}

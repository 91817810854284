import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {

  constructor(private httpClient: HttpClient) { }


    post(url, body, options = {}) {
      return this.httpClient.post(environment.BASE_URL + url, body, options);
    }

    get(url, options = {}) {
      return this.httpClient.get('https://v2-api.quran5051.org/public/api' + url, options);
    }

}

import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { finalize } from "rxjs/operators";
import { LoaderService } from '../shared/shared_services/loader.service';


const APP_ID = 25;

const AUTH_URLS = ['api/notifications', 'api/grants/store', 'api/grants_documents/store'];
const FILE_URLS = ['api/grants/pdf', '/association_financial_reports/export'];

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

    private requests: HttpRequest<any>[] = [];


  constructor(private translate: TranslateService,public loaderService: LoaderService) { }
    
   
  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.requests.push(req);
 
    console.log("No of requests--->" + this.requests.length);
       
    let duplicate = req.clone({ body: { ...req.body, 'application_id': APP_ID, lang_id: 2 } });

    let headers = duplicate.headers;

    AUTH_URLS.forEach(URL => {
      if (req.url.indexOf(URL) > -1) {
        headers = headers.set("access-token", localStorage.getItem('login_token'));
      }
    })
    FILE_URLS.forEach(url => {
      if(req.url.indexOf(url) > -1) {
        headers = headers.delete('Content-Type');
      }
    })
    duplicate = duplicate.clone({headers});
    // return next.handle(duplicate);

    this.loaderService.isLoading.next(true);
    return Observable.create(observer => {
      let subscription = next.handle(duplicate)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            // alert('error' + err);
            
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });




  }

  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
  //   // Start loader 
  //   this.loaderService.show();
  //   return next.handle(req).pipe(
  //       finalize(() => this.loaderService.hide())
  //   );

  //   // End loader

  //   let duplicate = req.clone({ body: { ...req.body, 'application_id': APP_ID, lang_id: 2 } });
  //   let headers = duplicate.headers;
  //   AUTH_URLS.forEach(URL => {
  //     if (req.url.indexOf(URL) > -1) {
  //       headers = headers.set("access-token", localStorage.getItem('login_token'));
  //     }
  //   })
  //   FILE_URLS.forEach(url => {
  //     if(req.url.indexOf(url) > -1) {
  //       headers = headers.delete('Content-Type');
  //     }
  //   })
  //   duplicate = duplicate.clone({headers});
  //   return next.handle(duplicate);



  //   //   if (req.url.indexOf('i18n') <= -1) {
  //   //     let headers = req.headers
  //   //         .set('X-localization', this.translate.currentLang);
  //   //     AUTH_URLS.forEach(URL =>{
  //   //         if(req.url.indexOf(URL) > -1){
  //   //             headers = headers.set("access-token" , localStorage.getItem('login_token'));
  //   //         }
  //   //     }) 

  //   //     const authReq = req.clone({ headers });
  //   //     return next.handle(authReq);
  //   // } else {
  //   //     return next.handle(req);
  //   // }


  // }

}
import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CommonGeneralService {

  constructor(private baseHttpService: BaseHttpService,private httpClient: HttpClient) { }


  getWhoWeAre() {
    return this.baseHttpService.post('/pages/show', {
      "id": 8,
    }, {})
  }

  getMission() {
    return this.baseHttpService.post('/pages/show', {
      "id": 11,
    }, {})
  }

  getVision() {
    return this.baseHttpService.post('/pages/show', {
      "id": 10,
    }, {})
  }

  getGoals() {
    return this.baseHttpService.post('/pages/show', {
      "id": 12,
    }, {})
  }

  getContactData() {
    return this.baseHttpService.post('/contact_details/show', {
      "id": 10,
    }, {})
  }

  submitContactForm(body) {
    // console.log("body od api",body);
    // console.log(body)
    // return this.baseHttpService.post('/contact_us/store', {body}, {})
  
    return this.httpClient.post<any>(`https://v2-api.quran5051.org/api/contact_us/store`,body);
  }

  getContactMessageTitle() {
    return this.baseHttpService.post('/contact_us_messages', {
      "application_id": 25,
      "lang_id":1
    }, {})
  }



  getFooterSocialLinks() {
    return this.baseHttpService.post('/social_media_values', {
      "module_id":420
    }, {})
  }

  getFriendlyWebsiteslist() {
    return this.baseHttpService.post('/cms', {
      "module_id": 430
    }, {})
  }

  getPrivacyPolicy() {
    return this.baseHttpService.post('/pages/show', {
      "id" : 17,
    }, {})
  }

  getTerms() {
    return this.baseHttpService.post('/pages/show', {
      "id" : 16,

    }, {})
  }

  getVisitors() {
    return this.baseHttpService.get('/visits');
  }

}

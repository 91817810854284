<div class="full-width">
    <div class="page-title">
        <div class="container">
            <h3>
            متابعة الطلبات
        </h3>
        </div>
    </div>
</div>
<div class="talabat-page">
    <div class="container">
        <div class="row table-style ">
            <div class="full-width search-container">
                <form class="row" style="margin-bottom: 25px;" >
                    <div class="col-md-6 col-sm-6 col-xs-12 pull-left">
                        <div class="row">
                            <span class=" col-md-3 col-sm-4 col-xs-12 pull-left">
                                <label class="search-title">البحث</label> 
                            </span> 
                            <span class=" col-md-9 col-sm-8 col-xs-12 pull-left">
                                <input class="search-input pull-left" type="text" placeholder="بحث ...." name="search"  [(ngModel)]="searchQuery"  [ngModelOptions]="{standalone: true}">
                            
                                <button class="btn-custom search-btn pull-left"  (click)="search()"><i class="fa fa-search"></i></button>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12 pull-left">
                        <button class="btn-custom-dark" (click)="ResetSearch()"> <i class="fa fa-times"></i> الغاء
                        </button>
                    </div>
                </form>
            </div>
    
            <div class="clearfix"></div>
            
            
            <div class="full-width row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <table>
                        <tr>
                            <th> </th>
                            <th>رقم الطلب</th>
                            <th>اسم البرنامج أو المشروع</th>
                            <th>حالة الطلب</th>
                            <th>التعليق علي الطلب</th>
                            <th>تاريخ تسجيل الطلب</th>
                            <th>التعديل </th>
                            <th>رفع التقرير</th>
                        </tr>
                        <tr *ngFor = "let item of talabatlist | paginate: { 
                                     itemsPerPage: pageSize,
                                     currentPage: currentPage,
                                     totalItems: totalArticles }; let i = index">
                                <td><input type="checkbox" (click)="DeleteListID($event,item.id)" value="item.id"></td>
                                <!-- <td>{{item.id}}</td> -->
                                <td>{{((currentPage - 1) * pageSize) + i + 1 }}</td>
                                <td>{{item.project_name}}</td>
                                <td>{{item.grant_status.name}}</td>
                                <td>{{item.comment}}</td>
                                <td>{{item.created_at}}</td>
                                <td>
                                    <button  class="edit-btn" *ngIf=" item.grant_status.name == 'معاد'" (click)="navigategrants(item.id)">
                                        <i class="fa fa-edit"></i>
                                        تعديل التقرير 
                                    </button>
                                    </td>
                                <td  style="width: 20%;">
                                    <form [formGroup]="TalabatForm">
                                        <div class="upload-btn-wrapper" *ngIf=" item.grant_status.name == 'مقبول'">
                                            <button  class="btnr" title="اختر ملف" type="button" >اختر ملف</button>
                                            <span *ngIf="uploaded_file1 == false">لم تقم باختيار ملف</span>
                                            <span>{{filename}}</span>
                                            <input class="" (change)="readFile($event,item.id)"  formControlName="final_report" type="file" name="myfile" title="اختر ملف" />
    
    
                                        </div>
                                        <button *ngIf=" item.grant_status.name == 'مقبول'" class="upload-btn" type="submit" (click)="submit()">رفع التقرير النهائي</button>
    
                                        <p *ngIf=" item.grant_status.name == 'مقبول' && item.final_report != null" class="uploud-done">	تم رفع التقرير</p>
                                    </form>
                                    
                                </td>
                                
                            
                            
        
        
                        </tr>
    
                        
    
                    </table>
    
                </div>
            </div>
    
            <div class="clearfix"></div>
                
            <div class="full-width row">
                <div class="col-md-2 col-sm-2 col-xs-2 ">
                    <button class="button delete-btn pull-left" (click)="DeleteTableRecords()">حذف المحدد</button>
                </div>
                <div class="col-md-10 col-sm-10 col-xs-10" style="margin-top: 20px;">
                    <pagination-controls   (pageChange)="pageChanged($event)" directionLinks="true" responsive="true"
                        previousLabel="السابق" nextLabel="التالى" style="margin: 20px 0px;">
                    </pagination-controls>
                </div>
            </div>
            <div class="clearfix"></div>
            <!-- <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 pull-left">
                    <pagination-controls   (pageChange)="pageChanged($event)" directionLinks="true" responsive="true"
                    previousLabel="السابق" nextLabel="التالى">
                </pagination-controls>
                </div>
            </div> -->
            
            
        </div>
    </div>

</div>



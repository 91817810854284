import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';
import { HttpHeaders,HttpRequest,HttpParams  } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TalabatService {

  header:HttpHeaders = new HttpHeaders();

  constructor(private baseHttpService: BaseHttpService) { }

  GettalabatList(currentPage) {
    console.log("current page from service:",currentPage)
    
    let headerNotification = this.header.set("access-token",localStorage.getItem('login_token'))
                                        // .set("page",`${currentPage}`)

    return this.baseHttpService.post('/grants', { "module_id": 501 , "page":`${currentPage}`}, {headers:headerNotification})
  }
  
  Submitreport(file){
    let headerNotification = this.header.set("access-token",localStorage.getItem('login_token'))
    file = {
      
      "module_id": 497,
      "grant_id": file.grant_id,
      "final_report":file.final_report
    }
    return this.baseHttpService.post('/grants/final_report/store', {...file}, {headers:headerNotification})
  }



  Searchtalabatlist(search) {
    let baseEventsObject = {
      "application_id": 25,
      "lang_id": 2,
      "module_id": 501,
      "text":search
    }
    let headerNotification = this.header.set("access-token",localStorage.getItem('login_token'))
                                        .set("page",'1')
    return this.baseHttpService.post('/grants/search',baseEventsObject,{headers:headerNotification}
    );
  }

  DeleteFromTable(IDsArray){
 
    let headerNotification = this.header.set("access-token",localStorage.getItem('login_token'))
    IDsArray = {
      "module_id":497,
      "ids": IDsArray,
    }
    // console.log("body of api",IDsArray)
    return this.baseHttpService.post('/grants/destroyall', {...IDsArray}, {headers:headerNotification})

  }


}
